import React from 'react';
import LoginModulesElementWebStandardWebSi from './LoginModulesElementWebStandardWebSi';
import './login.css';
import { useNavigate } from 'react-router-dom';
import {Helmet} from "react-helmet";

function Login(props) {
  const { loginModulesElementWebSingleSignOnB, loginModulesElementWebStandardWebSi } = props;
  let navigate = useNavigate();

  return (
    <div className="container-center-horizontal">
      <Helmet>
      <link rel="icon" type="image/png" href="favicon.ico"  />
                <title>URN | Login</title>
                
            </Helmet>
      <div className="login screen">
        <div className="overlap-group1">
          <div
            className="login-modules-background-image"
            style={{ backgroundImage: `url(${loginModulesElementWebSingleSignOnB.src})` }}
          ><LoginModulesElementWebStandardWebSi navigate={navigate}
              {...loginModulesElementWebStandardWebSi}
            /></div>

        </div>
      </div>
    </div>
  );
}


export default Login;