import LogoName from '../assets/images/Honeywell_LOGO.svg';
import background_image from '../assets/images/background_image.png';
const loginModulesElementWebSingleSignOnBData = {
    src: background_image,
};

const textField2Data = {
    children: "hello@honeywell.com",
};

const textField22Data = {
    children: "",
    className: "text-field",
};

const checkboxWebElementCheckIconData = {
    src: "",
};

const loginModulesElementWebStandardWebSiData = {
    logo: LogoName,
    titleLightTheme: "Email",
    titleLightTheme2: "Password",
    labelLightTheme: "Remember Me",
    buttonLightTheme: "SIGN IN",
    link1LightTheme: "Forgot Password",
    link2LightTheme: "Create New Account",
    textField2Props: textField2Data,
    textField22Props: textField22Data,
    checkboxWebElementCheckIconProps: checkboxWebElementCheckIconData,
};

export const loginData = {
    loginModulesElementWebSingleSignOnB: loginModulesElementWebSingleSignOnBData,
    loginModulesElementWebStandardWebSi: loginModulesElementWebStandardWebSiData,
};
