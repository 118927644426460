
import { Navigate, Route, useLocation } from 'react-router-dom';
import { getToken} from './common';

const PrivateRoute = ({ children }) => {
  let location = useLocation();

  const token = getToken();
  if (!token) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  return children;
};

export default PrivateRoute;