const ctaModulesElementWebSingleSignOnBData = {
    src: "./images/background_image.png",
};

const textField2Data = {
    children: "hello@honeywell.com",
};

const textField22Data = {
    children: "",
    className: "text-field",
};

const checkboxWebElementCheckIconData = {
    src: "",
};

const ctaModulesElementWebStandardWebSiData = {
    logo: "./images/Honeywell_LOGO.png",
    titleLightTheme: "Unique Challenge Number (UC-No.)",
    titleLightTheme2: "Device ID",
    buttonLightTheme: "Submit",
    textField2Props: textField2Data,
    textField22Props: textField22Data,
    checkboxWebElementCheckIconProps: checkboxWebElementCheckIconData,
};

export const ctaData = {
    ctaModulesElementWebSingleSignOnB: ctaModulesElementWebSingleSignOnBData,
    ctaModulesElementWebStandardWebSi: ctaModulesElementWebStandardWebSiData,
};
