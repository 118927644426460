import TextField2 from '../common/TextField2';
import React from "react";
import http from '../../services/httpSerice';
import { setUserSession } from '../../utils/common';
import { getToken} from '../../utils/common';
import UrnCode from '../urnCode/urnCode';
import { Oval } from  'react-loader-spinner'

class CtaModulesElementWebStandardWebSi extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uc: "",
      deviceId: "",
      formErrors: {
        uc: "",
        deviceId: ""
      },
      disabled: true,
      showDnaProgress: false,
      isUrn: false,
      urnCode:""
    };
    
  };
 
  handleChange = account => {
    let formErrors = { ...this.state.formErrors };
    let disabled = true;
    if (Object.keys(account).length > 0) {
      const [initialKey] = Object.keys(account)
      formErrors[initialKey] = "";
      formErrors.screenError = "";
      if (initialKey === 'uc') {
        if (account[initialKey].length === 10 && this.state['deviceId'].length === 16) {
          disabled = false
        }

      }
      if (initialKey === 'deviceId') {
        if (account[initialKey].length === 16 && this.state['uc'].length === 10) {
          disabled = false;
        }

      }
      this.setState({
        [initialKey]: account[initialKey], formErrors, disabled: disabled
      }
      );
    }
  
  };
  ctaValidation = (e) => {
    const { uc, deviceId } = this.state;
    const formErrors = { ...this.state.formErrors };

    if (!uc || /^\s*$/.test(uc)) {
      formErrors.uc = !uc || /^\s*$/.test(uc)
        ? "UC is mandatory"
        : "";
    } else {
      formErrors.uc = uc.length=== 10
        ? ""
        : "Please enter correct UC";
    }

    if (!deviceId || /^\s*$/.test(deviceId)) {
      formErrors.deviceId = !deviceId || /^\s*$/.test(deviceId)
        ? "Device ID is mandatory"
        : "";
    } else {
      formErrors.deviceId = deviceId.length=== 16
        ? ""
        : "Please enter correct Device ID";
    }

    return formErrors;
   
  };

  handleSubmit = event => {
    const token = getToken();
    const { uc, deviceId } = this.state;
    const formErrors = this.ctaValidation();
    if (formErrors.uc.length > 0 || formErrors.deviceId.length > 0) {
      this.setState({ formErrors, disabled: true, showDnaProgress: false })
    } else {
      if(!token || token === undefined || token === undefined) {
        this.props.navigate('/');
      }
      this.setState({ disabled: true, showDnaProgress: true })
      http.get(`/urn_code`,  {  headers: {'Access-Control-Allow-Origin' : '*',"Authorization" : `Bearer ${token}`}, params:{
        ucId: uc,
        deviceId: deviceId
      } },  
      ).then(response => {
        this.setState({isUrn: true, urnCode: String(response.data), disabled: false, showDnaProgress: false})
      }).catch((error) => {
        this.setState({ disabled: false, showDnaProgress: false})
        this.ctaFailure(error)
         
      });

    }
  }

    ctaFailure(error) {
      if (error.response && error.response.data) {
        let formErrors = { ...this.state.formErrors };
        if (error.response.data.error && error.response.data.error.ucError) {
          formErrors = { ...formErrors, ...{ uc: 'Please enter correct UC-No.' } };
          this.setState({ formErrors, disabled: true, showProgress: false });
        }
        if (error.response.data.error && error.response.data.error.deviceIdError) {
          formErrors = { ...formErrors, ...{ deviceId: 'Please enter correct Device ID' } };
          this.setState({ formErrors, disabled: true, showProgress: false })

        }
        else if (error.response.status === 401) { formErrors = { ...formErrors, ...{ deviceId: error.response.data.message } };
        this.setState({ formErrors, disabled: true, showProgress: false })
      }
    }
    
  };
  render() {
    const {
      logo,
      titleLightTheme,
      titleLightTheme2,
      buttonLightTheme,
      textField2Props,
      textField22Props
    } = this.props;
    const { uc, deviceId, formErrors, disabled, isUrn, urnCode, showDnaProgress } = this.state;
    return (
      isUrn?<UrnCode urn={urnCode}       urnTitle="URN Service"
      uniqueRefurbishing="UNIQUE REFURBISHMENT NUMBER (URN)"
      urnMessage="The above number will be valid for "
      urnTime="255 seconds"
      goBackToEntryScr="Go back to entry screen"
 />:<div className="cta-modules-elemen">
        <div className="cta-inner-container shadow-lg rounded">
          <div
            className="logo enter-details dark-theme"
           
          >ENTER THE DETAILS</div>
          <Oval
              height={50}
              width={50}
              color="#4fa94d"
              wrapperStyle={{}}
              wrapperClass=""
              visible={showDnaProgress}
              ariaLabel='oval-loading'
              secondaryColor="#4fa94d"
              strokeWidth={10}
              strokeWidthSecondary={10}

          />

          <div className="uc">
           
            <div className="title">
              <div className="title-required-dot-light-theme">
                <div
                  className="title-light-theme dark-theme h4--web--standard--left-align"
                >
                  {titleLightTheme} <span className={formErrors.uc.length > 0 ? "dot" : ""}></span>
                </div>
              </div>
            </div>
            <TextField2 maxLength="10"  className={formErrors.uc.length > 0 ? "is-invalid form-control" : "form-control"} type="text" name="uc" placeholder="Enter 10 digit UC-No." help="ucHelp" value={uc} handleChange={this.handleChange}>
              {textField2Props.children}
            </TextField2>
            {formErrors.uc.length > 0 && (
              <span className="errorMessage text-danger align-left">{formErrors.uc}</span>
            )}
           
          </div>
          <div className="device-id">
            <div className="title-1">
              <div className="title-required-dot-light-theme-1">
                <div
                  className="title-light-theme-1 dark-theme h4--web--standard--left-align"
                >
                  {titleLightTheme2}  <span className={formErrors.deviceId.length > 0 ? "dot" : ""}></span>
                </div>
              </div>
            </div>
            <TextField2
              name="deviceId" maxLength="16"   className={formErrors.deviceId.length > 0 ? "form-control is-invalid" : "form-control"} type="text" placeholder="Enter 16 digit Device ID" help="deviceIdHelp" handleChange={this.handleChange}>
              {textField22Props.children} <span className={formErrors.deviceId.length > 0 ? "dot" : ""}></span>
            </TextField2>
            {formErrors.deviceId.length > 0 && (
              <span className="errorMessage text-danger align-left">{formErrors.deviceId}</span>
            )}
          </div>
          <div className="cta-submit">
            <div className={disabled?"button-disabled":"button"}>
              <div  id="submitButtonCta" className= {`valign-text-middle dark-theme  ${disabled ? "light-theme" : "button-light-theme valign-text-middle light-theme button--solid"}`}
                onClick={disabled? "":this.handleSubmit}
              >
                {buttonLightTheme}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CtaModulesElementWebStandardWebSi;