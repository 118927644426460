function CheckboxWebElementCheckIcon(props) {
    const { src } = props;
  
    return (
      <div className="checkbox-web-el-ment-check-icon">
        <input type="checkbox" name="remember_me" disabled checked />
      </div>
    );
  }

  export default CheckboxWebElementCheckIcon;