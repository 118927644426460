import React from "react";
import { Link } from "react-router-dom";
import './urnCode.css';
export default function UrnCode(props) {
  return (<div className="cta-modules-elemen">
    <div className="cta-inner-container shadow-lg rounded">
      <div className="group-3">
        <div className="group-2">
          <div
            className="unique-refurbishing dark-theme h4--web--standard--left-align"
          >
            {props.uniqueRefurbishing}              </div>
          <div className="group">
            <div className="title">
              <div className="title-required-dot-light-theme">
                <h1 className="phone">
                {props.urn}
                </h1>
              </div>
            </div>
          </div>
        </div>
        <p
          className="date-hint-light-th dark-theme body-1--web--hint--left-align"
        >
          {/*<span className=" urn-message body-1--web--hint--left-align">{props.urnMessage}</span>
          <span className="dark-theme span2"> {props.urnTime}</span>*/}
        </p>
      </div>
      <div className="button-web-tabl-ight-background">
        <p
          className="go-back-to-entry-scr light-theme body-1--web--emphasis--left-align"
        >
       <Link to="/" className="go-back-to-entry-scr light-theme body-1--web--emphasis--left-align">Go back to entry screen</Link>   
        </p>
      </div>
    </div>
  </div>
  );
}
