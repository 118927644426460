import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './components/login/Login';
import Cta from './components/cta/Cta';
import http from './services/httpSerice';
import { loginData } from './constants/loginData'
import { ctaData } from './constants/ctaData'
import PrivateRoute from './utils/PrivateRoute';
import PublicRoute from './utils/PublicRoute';
import { getToken, setUserSession } from './utils/common';

class App extends React.Component {
  constructor() {
    super();
    this.state = { calls: 0, timeInterval: 180000 };
    this.refreshToken = this.refreshToken.bind(this);
  }
  componentDidMount() {
    const token = getToken();
    if (!token) {
      return;
    } else {
      setInterval(this.refreshToken, this.state.timeInterval);
    }
  }
  refreshTokenSuccess(response) {
    if (response.data.refreshToken) {
      setUserSession(response.data.refreshToken);
    }
  }

  async refreshToken() {
    const token = getToken();
    await http.get(`/refreshtoken`,
      { headers: { "isRefreshToken": true, "Authorization": `Bearer ${token}` } }).then(response => {
        this.refreshTokenSuccess(response)
      }).catch(error => {
        console.log("error", error);
      });
  }
  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<PublicRoute> <Login loginModulesElementWebSingleSignOnB={loginData.loginModulesElementWebSingleSignOnB}
              loginModulesElementWebStandardWebSi={loginData.loginModulesElementWebStandardWebSi} /> </PublicRoute>} />
            <Route
              path="/cta"
              element={
                <PrivateRoute>
                  <Cta
                    ctaModulesElementWebStandardWebSi={ctaData.ctaModulesElementWebStandardWebSi} />
                </PrivateRoute>
              }
            />
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}
export default App;
