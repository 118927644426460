import Logo from './logo';
import HeadeLine from '../../assets/images/header_line.svg';
function Header() {
    return (
      <div className="header shadow rounded">
        <Logo />
        <img src={HeadeLine} alt="line" className='heade-line'/>
        <div
          className="product-title-light-theme light-theme body-1--web--standard--left-align"
        >
          URN Service
        </div>
         <div className="badge bg-secondary rounded-circle header-badge">
        HW
      </div>
      </div>
    );
  }

  export default Header;