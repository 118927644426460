
import { Navigate, useLocation } from 'react-router-dom';
import { getToken} from './common';

const PublicRoute = ({ children }) => {
  let location = useLocation();

  const token = getToken();
  if (!token) {
    return children;
  }

  return <Navigate to="/cta" state={{ from: location }} />;
};

export default PublicRoute;