import TextField2 from '../common/TextField2';
import CheckboxWebElementCheckIcon from '../common/CheckboxWebElementCheckIcon';
import React from "react";
import http from '../../services/httpSerice';
import { setUserSession } from '../../utils/common';
import EyeOpen from '../../assets/images/eye.svg';
import EyeClose from '../../assets/images/eye-close.svg'
class LoginModulesElementWebStandardWebSi extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      formErrors: {
        email: "",
        password: "",
        screenError: ""
      },
      disabled: false,
      open: false,
      isIcon: false
    };

  };
  toggleEyeIcon = () => {
    this.setState(state => ({ open: !state.open }))
  }

  getEyeIconName = () => this.state.open ? EyeOpen : EyeClose;
  loginValidation = (e) => {
    const { email, password } = this.state;
    const formErrors = { ...this.state.formErrors };

    if (!email || /^\s*$/.test(email)) {
      formErrors.email = !email || /^\s*$/.test(email)
        ? "Email is mandatory"
        : "";
    } else {
      formErrors.email = emailRegex.test(email)
        ? ""
        : "Please enter correct email ID";
    }

    if (!password || /^\s*$/.test(password)) {
      formErrors.password = !password || /^\s*$/.test(password)
        ? "Password is mandatory"
        : "";
    } else {
      formErrors.password = "";
    }

    return formErrors;
  };
  handleChange = account => {
    let formErrors = { ...this.state.formErrors };
    if (Object.keys(account).length > 0) {
      const [initialKey] = Object.keys(account)
      formErrors[initialKey] = "";
      formErrors.screenError = "";
      let isIcon = this.state.isIcon;
      if (initialKey === 'password') {
        isIcon = false;
        if (account[initialKey].length > 0) {
          isIcon = true
        }

      }
      this.setState({
        [initialKey]: account[initialKey], formErrors, disabled: false, isIcon: isIcon
      }
      );
    }

  };

  handleSubmit = event => {
    const { email, password } = this.state;
    const formErrors = this.loginValidation();
    if (formErrors.email.length > 0 || formErrors.password.length > 0) {
      this.setState({ email, password, formErrors, disabled: true })
    } else {
      http.post('/login', {
        email: email,
        password: password
      }
      ).then(response => {
        setUserSession(response.data.token, response.data.user);
        this.props.navigate('/cta');
      }).catch((error) => {
        this.loginFailure(error);
      });

    }
  };

  loginFailure(error) {
    if (error.response && error.response.data) {
      let formErrors = { ...this.state.formErrors };
      if (error.response.data.error && error.response.data.error.emailError) {
        formErrors = { ...formErrors, ...{ email: 'Please enter correct email ID' } };
        this.setState({ formErrors, disabled: false });
      }
      if (error.response.data.error && error.response.data.error.passwordError) {
        formErrors = { ...formErrors, ...{ password: 'Please enter correct password' } };
        this.setState({ formErrors, disabled: false })

      }

      this.setState({ formErrors, disabled: false })
    }
  }
  changeIcon = (e) => {
    this.toggleEyeIcon();
  };
  render() {
    const {
      logo,
      titleLightTheme,
      titleLightTheme2,
      buttonLightTheme,
      textField2Props,
      textField22Props,
      labelLightTheme,
      link1LightTheme,
      link2LightTheme
    } = this.props;
    const { email, pasword, formErrors, disabled } = this.state;
    return (

      <div className="login-modules-elemen">
        <div className="login-inner-container">
          <div
            className="logo"
            style={{ backgroundImage: `url(${logo})` }}
          ></div>
          <div className="email">
            <div className="title">
              <div className="title-required-dot-light-theme">
                <div
                  className="title-light-theme dark-theme h4--web--standard--left-align"
                >
                  {titleLightTheme} <span className={formErrors.email.length > 0 ? "dot" : ""}></span>
                </div>
              </div>
            </div>
            <TextField2 maxLength="50"  className={formErrors.email.length > 0 ? "is-invalid-record form-control" : "form-control"} type="text" name="email" placeholder="Enter Email" help="emailHelp" value={email} handleChange={this.handleChange}>
              {textField2Props.children}
            </TextField2>
              <span className={formErrors.email.length === 0? "hide errorMessage light-theme text-danger align-left": "errorMessage light-theme text-danger align-left"}>{formErrors.email}</span>
            
          </div>
          <div className="password">
            <div className="title-1">
              <div className="title-required-dot-light-theme-1">
                <div
                  className="title-light-theme-1 dark-theme h4--web--standard--left-align"
                >
                  {titleLightTheme2} <span className={formErrors.password.length > 0 ? "dot" : ""}></span>
                </div>
              </div>
            </div>
            <TextField2 changeIcon={this.changeIcon} maxLength="50" isIcon={this.state.isIcon} iconSrc={this.getEyeIconName()}
              name="password"  className={formErrors.password.length > 0 ? "form-control is-invalid-record" : "form-control"} type={this.state.open ? 'text' : 'password'} placeholder="Enter Password" help="passwordHelp" handleChange={this.handleChange}>
              {textField22Props.children}
            </TextField2>
           
              <div className={formErrors.password.length === 0?"hide errorMessage light-theme text-danger align-left":"errorMessage light-theme text-danger align-left"}>{formErrors.password}</div>
           
          </div>
          <div className="remember-me">
            <div className="checkbox disabledbutton">
              <CheckboxWebElementCheckIcon
              />
            </div>
            <div
              className="label-light-theme dark-theme body-1--web--standard--left-align disabledbutton"
            >
              {labelLightTheme}
            </div>
          </div>

          <div className="sign-in-button">
            <div className="button">
              <div className={`button-light-theme dark-theme valign-text-middle light-theme button--solid ${disabled ? "disabledbutton" : ""}`}

                onClick={this.handleSubmit}
              >
                {buttonLightTheme}
              </div>
            </div>
          </div>
          <div className="link-1-light-theme light-theme button--solid disabledbutton">
            {link1LightTheme}
          </div>
          <div className="link-2-light-theme light-theme button--solid disabledbutton">
            {link2LightTheme}
          </div>

        </div>
      </div>
    );
  }
}
const emailRegex = RegExp(
  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
);
export default LoginModulesElementWebStandardWebSi;