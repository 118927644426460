import React from 'react';
import { useState } from 'react';



function TextField2(props) {
  const {  placeholder, type, help, name, className, isIcon, iconSrc, maxLength, changeIcon } = props;
  const [input, setInput] = useState(''); 
  const  handleChange =  event => {
    event.preventDefault();
    const { value, maxLength } = event.target;
    const inputValue = value.slice(0, maxLength);
   setInput(inputValue);
   props.handleChange({ [event.target.name]:inputValue});
  };
  return (
    <div className="text-field">
      <div className="overlap-group">
        <div className="outline">
          <div className="outline-color border-1px-celeste"></div>
        </div>
        <div
          className="hint-text-light-theme dark-theme body-1--web--hint--left-align"
        >
        <input value={input} type={type} maxLength= {maxLength} name={name}  onChange={(e)=>handleChange(e)} className={className} id="" aria-describedby={help} placeholder={placeholder} />
         {isIcon  && <img src={iconSrc} onClick={(e)=>changeIcon({e:e.target.src})} alt="icon" className="textfieldIcon" />} 
        </div>
      </div>
    </div>
  );
}

export default TextField2;