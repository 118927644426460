import axios from "axios";
const { REACT_APP_API_ENDPOINT } = process.env;

axios.interceptors.response.use(null, error => {
    return Promise.reject(error);
});
axios.defaults.baseURL = `${REACT_APP_API_ENDPOINT}`;
export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete
};