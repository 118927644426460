import React from 'react';
import CtaModulesElementWebStandardWebSi from './CtaModulesElementWebStandardWebSi';
import './cta.css';
import { useNavigate } from 'react-router-dom';
import {Helmet} from "react-helmet";
import Header from '../common/header';

function Cta(props) {
  const { ctaModulesElementWebStandardWebSi } = props;
  let navigate = useNavigate();

  return (
    <div className="container-center-horizontal">
       <Helmet>
      <link rel="icon" type="image/png" href="favicon.ico"  />
                <title>URN | CTA</title>
                
            </Helmet>
      <div className="cta screen">
        <div className="overlap-group1">
          <Header />
          <div
            className="cta-modules-background-image"
          ><CtaModulesElementWebStandardWebSi navigate={navigate}
              {...ctaModulesElementWebStandardWebSi}
            /></div>

        </div>
      </div>
    </div>
  );
}


export default Cta;